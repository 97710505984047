<div class="main-content">
  <div class="use-group">
    <mat-checkbox [checked]="!inputConfig" (click)="enableGlobalSettings()">
      {{ operationConfigComponent.globalConfigText }}
    </mat-checkbox>

    <mat-button-toggle-group
      [value]="config.isAiEnabled === undefined ? false : config.isAiEnabled"
      (change)="config.isAiEnabled = $event.value; toggleEnableAi()"
      [disabled]="isGlobalConfigApplied"
      class="ai-toggle">
      <mat-button-toggle [value]="false">Predefined Messages</mat-button-toggle>
      <mat-button-toggle [value]="true">AI Agent (Beta)</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="setting-wrapper">
    <div *ngIf="config?.isAiEnabled; else welcomeMessage">
      <app-operation-config-welcome-message-ai
        *ngIf="config?.aiConfig"
        [inputConfig]="config?.aiConfig"
        [isGlobalConfigApplied]="isGlobalConfigApplied">
      </app-operation-config-welcome-message-ai>
    </div>

    <ng-template #welcomeMessage>
      <div class="action-bar">
        <button
          [class.visibilityHidden]="isGlobalConfigApplied"
          class="add-icon"
          (click)="addMessage()"
          mat-mini-fab
          color="primary">
          <mat-icon>add</mat-icon>
        </button>
      </div>

      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let message of config.predefinedMessages; let last = last">
          <ng-container *ngIf="!last">
            <div cdkDrag class="follow-up-message-container">
              <ng-container
                *ngTemplateOutlet="messageContent; context: { $implicit: message, last: last }"></ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="follow-up-message-container" *ngIf="config.predefinedMessages.length > 0">
        <ng-container
          *ngTemplateOutlet="
            messageContent;
            context: { $implicit: config.predefinedMessages[config.predefinedMessages.length - 1], last: true }
          "></ng-container>
      </div>
    </ng-template>
  </div>
</div>

<ng-template #messageContent let-message let-last="last">
  <div class="card-actions">
    <button
      [class.visibilityHidden]="isGlobalConfigApplied"
      *ngIf="!last"
      (click)="removeMessage(message)"
      class="delete-icon"
      mat-mini-fab
      color="primary">
      <mat-icon>delete</mat-icon>
    </button>
    <svg
      [class.displayNone]="isGlobalConfigApplied"
      class="drag-handle"
      cdkDragHandle
      *ngIf="!last"
      width="24px"
      fill="currentColor"
      viewBox="0 0 24 24">
      <path
        d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
      <path d="M0 0h24v24H0z" fill="none"></path>
    </svg>
  </div>

  <mat-card class="follow-up-message">
    <mat-form-field floatLabel="always">
      <mat-label> Keywords that trigger this message as a welcome message</mat-label>
      <mat-chip-grid #chipGrid aria-label="Enter keywords" [disabled]="last || isGlobalConfigApplied">
        <mat-chip-row *ngFor="let word of message.words" (removed)="removeKeyword(message.words, word)">
          {{ last && word === "*" ? "Any" : word }}
          <button matChipRemove *ngIf="!last" aria-label="'remove ' + keyword">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <input
        placeholder="Add Keyword..."
        [class.displayNone]="last"
        [matChipInputFor]="chipGrid"
        (blur)="addElement(message.words, $event)"
        (matChipInputTokenEnd)="addElement(message.words, $event)"
        [disabled]="last || isGlobalConfigApplied" />
    </mat-form-field>

    <mat-form-field>
      <mat-label>Message</mat-label>
      <textarea
        cdkTextareaAutosize
        matInput
        minlength="2"
        required
        [disabled]="isGlobalConfigApplied"
        [(ngModel)]="message.text">
      </textarea>
    </mat-form-field>

    <mat-checkbox
      class="prevent-follow-ups-checkbox"
      [disabled]="isGlobalConfigApplied"
      [(ngModel)]="message.preventFollowUps"
      name="show">
      Prevent Follow-ups
    </mat-checkbox>
  </mat-card>
</ng-template>
